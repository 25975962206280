.Cart {
    border-top: 1px solid #eee;
    margin: 0 auto;
    max-width: 800px;
    padding-top: 2rem;
    text-align: center;
  }
  
  .Cart-total-cost {
    font-weight: 700;
    padding: 2rem;
  }